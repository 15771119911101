export const GET_CATEGORY = '/user/get-delivery-costing'
export const UPDATE_APP_SETTINGS = '/user/get-delivery-costing'
export const FETCH_SETTINGS = '/user/get-delivery-costing'
export const UPDATE_DELIVERY_COSTING_DATA = '/user/get-delivery-costing'
export const FETCH_DELIVERY_COSTING_DATA = '/user/get-delivery-costing'
export const FETCH_CONTACT = '/contact/fetch-contact'
export const CREATE_BLOG = '/contact/create-blog'
export const CREATE_USER = '/user/create-user'
export const FETCH_BLOG = '/contact/fetch-blog' 
export const FETCH_USER = '/user/fetch-user' 
export const UPLOAD_IMG = '/s3/upload-url'
export const FETCH_BLOG_ID = '/contact/fetch-blog-Id'
export const FETCH_USER_ID = '/user/fetch-user-Id'

