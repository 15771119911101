import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { withTranslation } from 'react-i18next';
import { isEmpty, get } from 'lodash';
import API from '../api'
import { GET_CATEGORY } from '../api/apiList'
import { handleError } from '../utils/helper'

class App extends Component {
  state = {
    isFullPageLayout: '',
    data: '',
    category:[],
    categoryCount:0
  };

  async componentDidMount() {
    this.onRouteChanged();
    // this.fetchCategories();
  }

  fetchCategories = async () => {
    API.post(GET_CATEGORY, { status: "published" }).then(({ data }) => {
      if (data.success) {
        localStorage.setItem('categories', JSON.stringify(get(data, 'result.result', [])))
      }
    })
      .catch(error => {
        handleError(error)
      })
  }

  render() {
    const { isFullPageLayout} = this.state;
    let hidePages = false;
    const localData = JSON.parse(localStorage.getItem('auth'))
    if (isEmpty(get(localData, 'data', {}))) {
      hidePages = true
    }
    const navbarComponent = !hidePages && !isFullPageLayout ? <Navbar /> : '';
    const sidebarComponent = !hidePages && !isFullPageLayout ? <Sidebar data={this.state.data} /> : '';
    const SettingsPanelComponent = !hidePages && !isFullPageLayout ? <SettingsPanel /> : '';
    const footerComponent = !hidePages && !isFullPageLayout ? <Footer /> : '';
    return (
      <div className='container-scroller'>
        {navbarComponent}
        <div className='container-fluid page-body-wrapper'>
          {sidebarComponent}
          <div className='main-panel'>
            <div className='content-wrapper'>
              <AppRoutes />
              {SettingsPanelComponent}
            </div>
            {footerComponent}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  

  onRouteChanged() {
    const body = document.querySelector('body');
    if (this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
    } else {
      body.classList.remove('rtl');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      '/login'
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector('.page-body-wrapper')
          .classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector('.page-body-wrapper')
          .classList.remove('full-page-wrapper');
      }
    }
  }
}

export default withTranslation()(withRouter(App));
