import axios from 'axios'
import { get } from 'lodash'
const API = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'content-type': 'application/json'
  },
})

API.interceptors.request.use(
  config => {
    let localData = JSON.parse(localStorage.getItem('auth'))
    if (localData && localData.token && !config.headers.authorization) {
      config.headers.authorization = `${localData.token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const err = get(error, 'response.data.error', '')
    if (
      get(err, 'statusCode', '') === 401 &&
      get(err, 'error', '') === 'Unauthorized'
    ) {
      localStorage.removeItem('auth')
    }
    return Promise.reject(error)
  }
)

export default API
